import React from "react"
import {graphql} from "gatsby";
import PageGabarit from "./modèles/page";
import styled from "styled-components";

const StyledPageGabarit = styled(PageGabarit)`
  main > div {
    max-width: 100%;
  
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      gap: 0 2%;
        
      @media screen and (min-width: 800px) {
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
      }
        
      li {
        display: block;
        box-sizing: border-box;
        @media screen and (min-width: 800px) {
          width: 48%;
        }
      }
    }
  }  
`


export const pageQuery = graphql`
    query($id: String!) {
        page: mdx(id: {eq: $id}) {
            ...PageContent
        }
    }
`

const MecenatGabarit = (props) => <StyledPageGabarit {...props}/>

export default MecenatGabarit
